import React, { useEffect, useState } from "react";
import {Link, NavLink, Route, Routes} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Contact from "./containers/Contact";
import Items from "./containers/Items";
import Trade from "./containers/Trade";
import Craft from "./containers/Craft";
import About from "./containers/About";
import Abilities from "./containers/Abilities";
import {faBars, faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const itemTemplate = {
  itemName: '',
  displayName: '',
  spriteId: '',
  type: '',
  description: '',
  isTradable: false,
  properties: {},
  createdAt: '',
  updatedAt: '',
  coordinates: {},
  recipeName: '',
  station: '',
  rarity: 0,
  consumes: [],
  produces: [],
  craftingTime: 0,
  shop: null,
};

function handleConfig(config) {
  let item, itemRecipe, viperShopItem, craftItems = [];

  for (let item_intrinsic of config.item_intrinsics) {
    item = null;
    itemRecipe = null;
    viperShopItem = null;

    // if (!item_intrinsic.isTradable) {
    //   continue;
    // }
    // if (config.exclude_items.by_itemName.includes(item_intrinsic.itemName)) {
    //   continue;
    // }
    // if (item_intrinsic.properties === undefined) {
    //   continue;
    // }

    item = {
      ...itemTemplate,
      itemName: item_intrinsic.itemName,
      displayName: item_intrinsic.displayName,
      spriteId: item_intrinsic.spriteId,
      type: item_intrinsic.type,
      properties: item_intrinsic.properties,
      description: item_intrinsic.description,
      isTradable: item_intrinsic.isTradable,
      createdAt: item_intrinsic.createdAt,
      updatedAt: item_intrinsic.updatedAt,
      recipeName: item_intrinsic.recipeName,
      rarity: item_intrinsic.rarity,
    };

    if (!config.icons_atlas_metadata[item.spriteId]) {
      continue;
    }

    item.coordinates = config.icons_atlas_metadata[item.spriteId];

    // viper items is priority as it rewrote in the game above item's default craft
    viperShopItem = config.viper_shop.vendorEntries.find(viperItem => {
      return viperItem.itemName === item.itemName;
    });

    // old logic with the "first found recipe to the list"
    // // (recipe.displayName === item.displayName) || (recipe.spriteId === item.spriteId)
    // itemRecipe = config.recipes.find(recipe => {
    //   return recipe.spriteId === item.spriteId
    //     && recipe.craftingTime > 0
    //     // exclude upgrade items, looks like a hack
    //     && (!recipe.chanceOutput || recipe.chanceOutput.length === 0)
    //   ;
    // });

    // new logic with the "last found recipe to the list"
    config.recipes.forEach(recipe => {
      // if (item_intrinsic.itemName === 'ches_AC0002' && recipe.spriteId === 'chest_ninja_icon') {
      //   console.log('********************');
      //   console.log(item_intrinsic);
      //   console.log(recipe);
      //   console.log('********************');
      // }
      if (!recipe.produces) {
        return;
      }
      let isProducedByRecipe = false;
      for (let producesItem of recipe.produces) {
        if (item_intrinsic.itemName === producesItem.itemName) {
          isProducedByRecipe = true;
        }
      }
      if (
        recipe.craftingTime > 0 &&
        // removed after v4.36.12, 20.09.2024, checked not a lot, but worked
        // recipe.spriteId === item.spriteId &&
        isProducedByRecipe &&
        // exclude upgrade items, looks like a hack
        (!recipe.chanceOutput || recipe.chanceOutput.length === 0)
      ) {
        itemRecipe = recipe;
      }
    });

    if (itemRecipe) {
      item.produces = itemRecipe.produces;
      item.station = itemRecipe.station;
      item.recipeName = itemRecipe.recipeName;
      item.consumes = itemRecipe.consumes;
      item.craftingTime = itemRecipe.craftingTime;
    }

    // hardcode viper's price in consumes
    if (viperShopItem) {
      item.consumes = [{
        itemName: "gold",
        quantity: viperShopItem.cost
      }];
      item.craftingTime = 1;
      item.shop = 'viper';
    }

    craftItems.push(item);
  }

  document.config = config;
  document.craftItems = craftItems;

  // console.log('Config loaded:', config);
  // console.log('Items:', craftItems);
}

function App() {
  const [isLoading, setLoading] = useState(true);
  const [isConfigError, setConfigError] = useState(false);
  const [isActiveMenu, setActiveMenu] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleMenuClick = () => {
    setActiveMenu(false);
  };

  useEffect(() => {
    fetch('/config.json?v=1')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(config => {
        handleConfig(config);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setTimeout(function () {
          setLoading(false);
          setConfigError(true);
        }, 500);
      });

    const toggleVisibility = () => {
      if (window.pageYOffset > 700) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (isLoading) {
    return (
      <div className="loading-global">
        <span className="loading-global-text">Loading<span>.</span><span>.</span><span>.</span></span>
      </div>
    );
  }

  if (isConfigError) {
    return (
      <div className="config-error">
        <span className="config-error-text">Config error<span>.</span><span>.</span><span>.</span></span>
      </div>
    );
  }

  return (
    <div className="page">
      <div className="header">
        <div className="wrapper header-content">
          <div className="menu-mobile-button-wrapper">
            <button className="menu-mobile-button">
              <FontAwesomeIcon icon={faBars} onClick={() => setActiveMenu(!isActiveMenu)}/>
            </button>
          </div>
          <div className="logo">
            <Link to="/">
              <span className="logo-www">Soul<span className="logo-b">bound</span></span>
            </Link>
          </div>
        </div>
        <div className={'menu ' + (isActiveMenu ? 'active' : '')}>
          <button className="menu-mobile-button-close">
            <FontAwesomeIcon icon={faClose} onClick={() => setActiveMenu(!isActiveMenu)}/>
          </button>
          <ul className="menu-list">
            {/*<li onClick={handleMenuClick}>*/}
            {/*  <NavLink to="/trade" className={({ isActive }) => "list" + (isActive ? " active" : "")}>Trade</NavLink>*/}
            {/*</li>*/}
            {/*<li onClick={handleMenuClick}>*/}
            {/*  <NavLink to="/" className={({ isActive }) => "list" + (isActive ? " active" : "")}>Home</NavLink>*/}
            {/*</li>*/}
            <li onClick={handleMenuClick}>
              <NavLink to="/craft" className={({ isActive }) => "list" + (isActive ? " active" : "")}>Craft</NavLink>
            </li>
            <li onClick={handleMenuClick}>
              <NavLink to="/abilities" className={({ isActive }) => "list" + (isActive ? " active" : "")}>Abilities</NavLink>
            </li>
            <li>
              <a target="_blank" href="https://wiki.soulbound.game/Soulbound_Wiki_Home" className="list">Wiki 🗗</a>
            </li>
            <li onClick={handleMenuClick}>
              <NavLink to="/about" className={({ isActive }) => "list" + (isActive ? " active" : "")}>About</NavLink>
            </li>
            {/*<li onClick={handleMenuClick}>*/}
            {/*  <NavLink to="/items" className={({ isActive }) => "list" + (isActive ? " active" : "")}>Items</NavLink>*/}
            {/*</li>*/}
            {/*<li onClick={handleMenuClick}>*/}
            {/*  <NavLink to="/contact" className={({ isActive }) => "list" + (isActive ? " active" : "")}>Contact</NavLink>*/}
            {/*</li>*/}
          </ul>
          <span className="version-text">v{document.config.version}</span>
        </div>
      </div>

      <ToastContainer />

      <>
        {isVisible && (
          <button
            onClick={scrollToTop}
            className="scroll-to-top"
          >
            &#x25B2;
          </button>
        )}
      </>

      <Routes>
        <Route path="/" element={<Craft />} />
        <Route path="/trade" element={<Trade />} />
        <Route path="/craft" element={<Craft />} />
        <Route path="/items" element={<Items />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/abilities" element={<Abilities />} />
      </Routes>
    </div>
  );
}

export default App;